* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css2?family=Alegreya&family=Alegreya+SC&display=swap");
/* Root Variables */
:root {
  --white-0: #ffffff;
  --main-bg-color: #f5f5f5;
  --main-bg-color-dark: #001529;
  --primary-color: #1677ff;
}
/* HTML */
html {
  font-size: 62.5%;
}
body {
  background-color: var(--main-bg-color-dark);
}

/* Dark Mode */
.dark-mode {
  position: fixed;
  color: var(--main-bg-color);
  right: 0;
  cursor: pointer;
  z-index: 100;
  padding: 10px;
  transition: color 0.3s;
}
.dark-mode:hover {
  color: var(--primary-color);
}
/* Header */
.logo {
  font-family: "Alegreya SC", serif;
  font-size: 1.8rem;
  float: left;
  padding-right: 10px;
}
.logo a {
  color: var(--white-0) !important;
}
.nav-stats {
  float: right;
}
/* Home */
.home-stats {
  padding: 20px;
}
.home-feature {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}
.home-link {
  animation: move-left-right 0.5s ease-in-out infinite alternate;
}
.home-link:hover {
  animation-play-state: paused;
}
@keyframes move-left-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10px);
  }
}
/* Main */
.ant-layout-content {
  min-height: calc(100vh - 146px) !important;
}
.title-container {
  padding: 20px;
}
.search-ape {
  padding: 20px;
  padding-top: 0;
  width: 200px;
}
.sort-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding: 0 20px 0 20px;
}
.sort-dropdown {
  display: none;
  padding: 0 20px 0 20px;
}
.main-apes {
  padding: 20px 20px 0 20px;
}
.lost-apes-list {
  margin-bottom: 0 !important;
}
.lost-wallets {
  padding: 0 20px;
  max-width: 600px;
}
.lost-wallets-btn {
  padding-left: 5px;
  padding-bottom: 10px;
}
.lost-wallets-btn button {
  padding-top: 0 !important;
}
.loader {
  min-height: calc(100vh - 146px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.error {
  min-height: calc(100vh - 146px);
  padding: 20px;
  color: red;
  text-align: center;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ape-details-container {
  padding: 20px;
  display: flex;
}
.ape-details-image {
  width: 50%;
  padding: 20px;
  padding-top: 0;
}
.ape-details-card {
  border-radius: 25px !important;
}
.ape-details-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: 20px;
  padding-top: 0;
}
.ape-details-links {
  padding: 20px;
  padding-bottom: 0;
}

/* Footer */
.ant-layout-footer {
  height: 82px !important;
}
.footer-blank {
  width: 33%;
}
.footer-text {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.footer-logos {
  width: 33%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  font-size: 28px;
}

/* Sort Dropdown */
@media screen and (max-width: 405px) {
  .sort-container {
    display: none;
  }
  .sort-dropdown {
    display: flex;
  }
  .ant-card-body {
    padding: 10px !important;
  }
  .footer-text {
    font-size: 1rem;
  }
  .ant-layout-header {
    padding-inline: 24px !important;
  }
}

/* Ape Details */
@media screen and (max-width: 768px) {
  .ape-details-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .ape-details-image {
    width: 100%;
    padding: 0;
  }
  .ape-details-body {
    width: 100%;
    padding: 0;
    align-items: center;
  }
  .ape-details-traits {
    order: 2;
  }
  .ape-details-traits-row {
    justify-content: center;
  }
  .ape-details-links {
    display: flex;
    order: 1;
    padding: 0;
    padding-bottom: 20px;
  }
  .ape-details-image-row {
    justify-content: center !important;
  }
}

/* Footer */
@media screen and (max-width: 500px) {
  .ant-layout-footer {
    padding: 12px 12px !important;
  }
}
